import React from 'react'
import styled from 'styled-components'
import { Header } from 'components'
import { media } from 'resources'

export const TestimonialPageHeader = () => {
  return (
    <Wrapper>
      <Header />
      <Content>
        <ContentWrapper>
          <HeaderTitle>
            <span>Conheça os estudantes que conquistaram a sua aprovação.</span>
            <span>Vem com a gente aqui!</span>
          </HeaderTitle>

          <HeaderRightSideContent style={{ marginTop: '8rem' }}>
            <ImageSet>
              <img src='/mural-aprovados/1.png' alt='Joaquim Cavalcante' />
              <img src='/mural-aprovados/2.png' alt='Alice Brayner e Matheus Costa' />
            </ImageSet>
            <ImageSet style={{ marginTop: '10rem' }}>
              <img src='/mural-aprovados/3.png' alt='Haressa Oliveira' />
              <img src='/mural-aprovados/4.png' alt='Lucas Oscioni' />
            </ImageSet>
          </HeaderRightSideContent>
        </ContentWrapper>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.header``

const Content = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.primary};
  width: 100%;
  height: 700px;

  ${media.lessThan('md')`
    height: auto;
    padding: 12rem 3rem;
  `}
`

const ContentWrapper = styled.div`
  max-width: 120rem;
  width: 100%;
  height: 100%;
  padding-top: 39px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: auto;
    height: 100%;
    padding-top: 4rem;
  }

  ${media.lessThan('md')`
    justify-content: center;
    text-align: center;
  `}
`

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 48px;
  font-weight: bold;
  color: white;
  flex: 60;

  span:last-child {
    color: ${({ theme }) => theme.colors.accent};
  }

  ${media.lessThan('md')`
    font-size: 24px;
  `}
`

const HeaderRightSideContent = styled.div`
  flex: 40;
  display: grid;
  grid-template-columns: 1fr 1fr;

  img {
    padding: 0 !important;
    width: 263px;
    height: auto;
  }

  ${media.lessThan('md')`
    display: none;
  `}
`
const ImageSet = styled.div`
  display: flex;
  flex-direction: column;
`

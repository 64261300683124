import React, { useRef } from 'react'
import styled from 'styled-components'
import { Layout, Seo, Footer, LeadCapture, Video } from 'components'
import { media, theme } from 'resources'
import { TestimonialCard } from 'components/testimonials/testimonial-card'
import { SubscribeNowButton } from 'components/UI/SubscribeNowButton'
import ReactPaginate from 'react-paginate'
import { TestimonialPageHeader } from 'components/testimonials/testimonial-page-header'
import { graphql } from 'gatsby'
import t from 'prop-types'
import { HorizontalDivider } from 'components/UI/HorizontalDivider'
import Slider from 'react-slick'
import SlickDots from 'components/teachers/components/SlickDots'
import { sortByField } from 'resources/helpers/sort-helper'

const ITEMS_PER_PAGE = 9
const VIDEO_SLIDE_BREAKPOINTS = [
  {
    breakpoint: 900,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 700,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
]

const TestimonialsPage = ({ data }) => {
  const sliderRef = useRef(null)
  const [itemOffset, setItemOffset] = React.useState(0)
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const testimonialsContent = data.api.allTestimonials

  const textTestimonials = testimonialsContent.filter(
    (item) => item.testimonialType === 'text'
  )
  const videoTestimonialsData = testimonialsContent.filter(
    (item) => item.testimonialType === 'video'
  )

  sortByField(textTestimonials, 'viewOrder')
  sortByField(videoTestimonialsData, 'viewOrder')

  const endOffset = itemOffset + ITEMS_PER_PAGE
  const currentItems = textTestimonials.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(textTestimonials.length / ITEMS_PER_PAGE)

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * ITEMS_PER_PAGE) % textTestimonials.length
    setItemOffset(newOffset)
  }

  const videoSliderSettings = {
    dots: false,
    infinite: videoTestimonialsData.length > 3,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next)
    },
    responsive: VIDEO_SLIDE_BREAKPOINTS,
  }

  return (
    <Layout>
      <Seo />
      <TestimonialPageHeader />
      <Main>
        <TestimonialsWrapper>
          <Title>Depoimentos</Title>

          <Content>
            <VideoTestimonials>
              <Slider ref={sliderRef} {...videoSliderSettings}>
                {videoTestimonialsData.map((data, index) => (
                  <VideoWrapper key={index}>
                    <Video
                      pic={data.image}
                      name={data.name}
                      description={data.description}
                      url={data.content}
                    />
                  </VideoWrapper>
                ))}
              </Slider>
              <SlickDots
                sliderRef={sliderRef}
                slideCount={videoTestimonialsData.length}
                sliderSettings={videoSliderSettings}
                currentSlide={currentSlide}
                accentColor={theme.colors.primary}
                hoverColor={theme.colors.primary}
              />
            </VideoTestimonials>

            <HorizontalDivider />

            <TextTestimonials>
              {currentItems.map((data, index) => (
                <TestimonialCard key={index} data={data} />
              ))}
            </TextTestimonials>
          </Content>

          {pageCount > 1 && (
            <PaginationWrapper>
              <ReactPaginate
                breakLabel='...'
                nextLabel='>'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel='<'
                renderOnZeroPageCount={null}
                className='pagination'
                pageClassName='pagination__page'
                previousClassName='pagination__previous'
                nextClassName='pagination__next'
              />
            </PaginationWrapper>
          )}

          <SubscribeButtonWrapper>
            <SubscribeNowButton
              customUrl='/#planos'
              textContent='ASSINAR AGORA'
            />
          </SubscribeButtonWrapper>
        </TestimonialsWrapper>
      </Main>
      <LeadCapture border />
      <Footer />
    </Layout>
  )
}

const Main = styled.main`
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-width: 120rem;

  ${media.lessThan('lg')`
    max-width: 70rem;
  `}

  ${media.lessThan('sm')`
    max-width: 42rem;
  `}

  ${media.lessThan('xsm')`
    max-width: 38rem;
  `}

  ${media.lessThan('xxsm')`
    max-width: 25rem;
  `}
`

const VideoTestimonials = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${media.lessThan('md')`
    gap: 3rem;
    padding-top: 2rem;
  `}
`

const TextTestimonials = styled.div`
  display: grid;
  grid-gap: 2rem;
  width: 100%;
  padding: 0 2rem;

  grid-template-columns: repeat(3, 1fr);

  ${media.lessThan('md')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.lessThan('sm')`
    grid-template-columns: 1fr;
  `}

  ${media.lessThan('xxsm')`
    padding: 0;
  `}
`

const TestimonialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 8rem 0;

  ${media.lessThan('md')`
    padding: 3rem 0;
  `}
`

const Title = styled.span`
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  margin-left: 1rem;
  margin-bottom: 4rem;

  ${media.lessThan('md')`
    text-align: center;
    margin-bottom: 2rem;
  `}

  ${media.lessThan('xsm')`
    font-size: 30px;
  `}
`

const SubscribeButtonWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const VideoWrapper = styled.div`
  padding: 0 2rem;
  * {
    outline: none;
  }
`

TestimonialsPage.propTypes = {
  data: t.object,
}

export const query = graphql`
  query {
    api {
      allTestimonials {
        testimonialType
        name
        image
        content
        description
        viewOrder
      }
    }
  }
`

export default TestimonialsPage
